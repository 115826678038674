<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/lawService/banner.jpg" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterpriseService' }">基础服务</el-breadcrumb-item>
        <el-breadcrumb-item>法律服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container_title">法律顾问服务内容</div>



    <div class="containerbox">
      <div class="containerbg">
        <img src="../../../assets/img/home/lawService/1.png" style="width: 100%;height: 100%;" alt="" />
      </div>
      <div class="containerName">参与起草、审核企业规章制度</div>
    </div>
    <div class="container_dsc">企业规章制度是企业文化、管理方式、管理水平的综合体现，企业法律顾问通过对企业规章制度的起草、修改和审查，对其合法性、实用性、规范性、协调性提供保障。</div>


    <div class="containerbox">
      <div class="containerbg">
        <img src="../../../assets/img/home/lawService/2.png" style="width: 100%;height: 100%;" alt="" />
      </div>
      <div class="containerName">参与企业重大经营决策</div>
    </div>
    <div class="container_dsc">企业重大经营决策包括资本经营、产品开发、市场营销等，企业法律顾问通过参与企业重大经营决策，确保该等决策的合法性和可行性，主要方式有</div>


    <div class="firmBox">
      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc1.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">参与企业重大经营决策</div>
      </div>


      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc2.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">为企业领导决<br />策提供咨询意见</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc3.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">通过起草、修改、审核有关文件<br />参与企业重大经营决策</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc4.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">企业法律顾问<br />主动提出意见和方案</div>
      </div>
    </div>

    <div class="containerbox">
      <div class="containerbg">
        <img src="../../../assets/img/home/lawService/3.png" style="width: 100%;height: 100%;" alt="" />
      </div>
      <div class="containerName">审查合同</div>
    </div>
    <div class="container_dsc">合同是企业对外从事经济活动的必不可少的环节之一，很多法律风险往往就隐藏在签订合同的过程中。企业法律顾问对企业内部各业务部门起草的合同
      进行审查把关，确保合同主体、数量、价格、质量、结算方式、违约责任、文字、签约程序等方面规避风险。</div>


    <div class="containerbox">
      <div class="containerbg">
        <img src="../../../assets/img/home/lawService/4.png" style="width: 100%;height: 100%;" alt="" />
      </div>
      <div class="containerName">协助合同管理</div>
    </div>
    <div class="container_dsc">企业法律顾问协助企业管理合同业务涉及以下几个方面</div>


    <div class="firmBox">
      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc1.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">授权委托书的管理</div>
      </div>


      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc2.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">对合同文本及合同<br />专用章的管理</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc3.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">对合同管理情<br />况的监督检查</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc4.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">对合同纠纷的解决<br />情况进行监督检查</div>
      </div>
    </div>

    <div class="containerbox">
      <div class="containerbg">
        <img src="../../../assets/img/home/lawService/5.png" style="width: 100%;height: 100%;" alt="" />
      </div>
      <div class="containerName">办理商标、专利和商业秘密法律事务</div>
    </div>
    <div class="container_dsc">企业法律顾问通常通过两种方式来开展此项业务</div>



    <div class="firmBox">
      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc1.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">授权委托书的管理</div>
      </div>


      <div class="firmItem" style="margin-left: 25%;">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/jc2.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">对合同文本及合同<br />专用章的管理</div>
      </div>
    </div>


    <div class="lawserviceBox">

      <div class="law_title">专项法律服务</div>
      <div class="law_box">
        <div class="law_item">
          <div> 1.为企业设立、合并和分立事务提供法律解决方案。</div>
          <div> 2.为企业股权激励计划和员工激励计划提供法律解决方案。</div>
          <div> 3.为企业改制、对外投资，资产转让专项事务提供法律解决方案。 </div>
          <div> 4.为企业股权转让、增资、新三板挂牌事务提供法律解决方案。 </div>
          <div> 5.为企业提供涉及税务筹化的法律解决方案。 </div>


        </div>
        <div class="law_item">
          <div>6.为企业内部股权纠纷处理提供法律解决方案。 </div>
          <div> 7.为企业商务合同及对外债权、债务纠纷处理提供法律解决方案。</div>
          <div> 8.为企业变更、注销、资产清算专项事务提供法律解决方案。</div>
          <div> 9.为企业提供商务谈判和法律顾问服务。</div>
          <div> 10.为企业其他重要经济活动提供法律解决方案。 </div>
        </div>
      </div>

    </div>

    <div class="container_title">服务流程</div>

    <div class="firmBox">
      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/lc1.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">在线咨询</div>
      </div>


      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/lc2.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">预约面谈</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/lc3.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">签订协议</div>
      </div>

      <div class="firmItem">
        <div class="firmBg">
          <img src="../../../assets/img/home/lawService/lc4.png" style="width: 100%;height: 100%;" alt="" />
        </div>
        <div class="firmBgName">案情服务</div>
      </div>
    </div>
    <div class="process_btn" @click="handleApply">立即联系</div>
    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      registerPhone: "",
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
      },
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "20";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() { },
  mounted() { },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 3%;
}

.containerbox {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
}

.containerbg {
  width: 2%;
  margin-top: 2%;

}

.containerName {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #055AFE;
  margin-top: 5px;
}



.container_dsc {
  margin: 10px auto;
  width: 65%;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #949494;
  line-height: 30px;

}


.firmBox {
  width: 60%;
  height: 180px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 3% auto;
}

.firmItem {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.firmBg {
  flex: 2;
}

.firmBgName {
  flex: 1;
  width: 90%;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}



.lawserviceBox {
  width: 100%;
  height: 300px;
  background-color: #055AFE;
}

.law_title {
  width: 100%;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  margin: 2% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  padding-top: 2%;
}

.law_box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;


}

.law_item div {
  line-height: 30px;
}





.process_btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  background: #055afe;
  cursor: pointer;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
